<template>
  <div ref="tooltip" id="MD_tooltip-card">
    <table id="legend-table" role="presentation">
      <tr id="legend-tr">
        <td
          id="legend-td"
          aria-label="tooltip data"
          v-for="(legend, idx) in Object.keys(legends)"
          :key="idx"
        >
          <span class="legend-label" :aria-label="legends[legend]">
            {{ legend }}
          </span>

          <span class="legend-value" :aria-label="legends[legend].value">
            {{ legends[legend].value }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['chartGetter'])
  },
  mounted() {
    this.symbol = this.$store.getters.symbol
    const chart = this.chartGetter()
    chart.eventEmitter.on('CROSSHAIR_MOVE', (args) => {
      if (!args?.activePanel?.isUpper) {
        this.hideTooltip()
      } else {
        this.updateTooltip(args)
      }
    })
    chart.eventEmitter.on('CROSSHAIR_HIDE', () => {
      this.hideTooltip()
    })
  },
  data() {
    return {
      intra: 1,
      volume: null,
      open: null,
      high: null,
      low: null,
      close: null,
      perc: null,
      diff: null,
      positionTop: null,
      positionLeft: null,
      legends: {}
    }
  },
  watch: {
    volume() {
      const legends = {
        'Open: ': { value: this.open },
        'High: ': { value: this.high },
        'Low: ': { value: this.low },
        'Close: ': { value: this.close }
      }

      if (this.$store.state.chart.days > 5) {
        legends['$ Chg: '] = { value: this.diff }
        legends['% Chg: '] = { value: this.perc }
      }

      legends['Vol: '] = { value: this.volume }

      this.legends = legends
    }
  },
  methods: {
    updateTooltip(args) {
      this.open = args.open.toFixed(2)
      this.high = args.high.toFixed(2)
      this.low = args.low.toFixed(2)
      this.close = args.close.toFixed(2)
      this.diff = this.getDiff(args)
      this.perc = this.getPerc(args)
      this.volume = this.formatVolume(args.volume)
      this.intra = this.$store.getters.interval
      this.positionCrossHair(args)
    },
    hideTooltip() {
      const el = this.$refs.tooltip
      if (el) {
        el.style.display = 'none'
      }
    },
    positionCrossHair(args) {
      const chart = this.chartGetter()
      const el = this.$refs.tooltip
      const margin = 1
      const width = el.offsetWidth
      const height = el.offsetHeight
      let left = margin + args.coords.x
      let top = margin + args.coords.y
      // bump left if right collision
      if (left + width > chart.size.width - width / 2) {
        left = args.coords.x - width - margin
      }
      // bump up if bottom collision
      let chartHeight = chart.panels[0].size.height - 20
      if (top + height > chartHeight) {
        top = args.coords.y - height - margin
      }
      el.style.display = 'block'
      this.positionTop = 3
      this.positionLeft = 3
    },
    getPerc(args) {
      const diff = args.panels[0]?.indicators[0]?.data?.diff
      const priorClose = args.close
      return (diff && priorClose) ? ((diff / priorClose) * 100).toFixed(2).toString() : '--'
    },
    getDiff(args) {
      const diff = args?.panels[0]?.indicators[0]?.data?.diff
      return (diff) ? parseFloat(diff.toFixed(2)).toString() : '--'
    },
    formatVolume(vol) {
      return vol ? new Intl.NumberFormat('en-US').format(vol) : null
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@500&display=swap');
#MD_tooltip-card {
  font-size: 10px;
  width: 98%;
  position: absolute;
  margin-top: -21px;
  margin-bottom: 6px;
  padding-top: 1px;
  padding-bottom: 2px;
  border-bottom: 0px;
  td {
    border-bottom: 0px;
  }

  #legend-table {
    border-spacing: 0px;
    border: none;
  }
  #legend-tr {
    padding: 1px 1px 1px 1px;
  }
  #legend-td {
    font-weight: 600;
    padding: 1px 1px 1px 1px;
    min-width: 72px;
  }
  .legend-label {
    color: #374761;
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
  }
  .legend-value {
    color: #374761;
  }
}
</style>
