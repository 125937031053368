'use strict'

import * as types from './mutation-types'
import service from '_service'
import { tokenExpiredEvent } from '_helpers/events'
import { ModchartsInteractive } from '@markit/modcharts/dist/modcharts.esm.js'

/* -------------------------------------------
  XREF SECTION
------------------------------------------- */

export const xrefPredictiveSearch = ({ commit }, term) => {
  commit(types.XREF_SUCCESS, false)
  const params = {
    term,
    exchangeCountryIsos: 'US'
  }
  return service.xref
    .get('/venues', params)
    .then((response) => response.data.data)
    .then((data) =>
      data.items && data.items.length > 0 ? data.items[0] : null
    )
    .then((data) => {
      if (!data) return
      commit(types.XREF_SUCCESS, true)
      commit(types.XREF_CURRENT_XIDS, data.xids)
      commit(types.CHART_VENUE, data.xids.venue)
      commit(types.CHART_SYMBOL, data.symbol)
      commit(types.XREF_COMPANY_NAME, data.name)
    })
    .catch((error) => {
      if (error.response && error.response.status.toString() === '401') {
        tokenExpiredEvent()
      }
      console.error(error)
    })
}

/* -------------------------------------------
  QUOTE SECTION
------------------------------------------- */

export const getQuoteData = ({ commit, state }) => {
  commit(types.QUOTE_SUCCESS, false)
  if (!state.xref.currentXids || !state.xref.currentXids.venue) return

  const params = {
    venueXids: state.xref.currentXids.venue,
    marketHoursTradeType: 'normal',
    clearPremarketQuote: false
  }
  return service.quote
    .get('/details', params)
    .then((response) => response.data.data)
    .then((data) =>
      data.quotes && data.quotes.length > 0 && data.quotes[0].data
        ? data.quotes[0].data
        : null
    )
    .then((data) => {
      if (!data) return
      commit(types.QUOTE_SUCCESS, true)
      commit(types.QUOTE_DETAILS, data)
    })
    .catch(console.error)
}

/* -------------------------------------------
  CHART WORKS
------------------------------------------- */

export const getinceptionDate = ({ commit, state }) => {
  if (!state.xref.currentXids || !state.xref.currentXids.venue) return

  const params = {
    symbol: state.xref.currentXids.venue
  }
  return service.chart
    .get('/InceptionDate', params)
    .then((response) => response.data)
    .then((data) => {
      if (!data || !data.InceptionDate) {
        commit(types.INCEPTION_DATE, null)
      } else {
        commit(types.INCEPTION_DATE, data.InceptionDate)
      }
    })
    .catch(console.error)
}

/* -------------------------------------------
  CHART INTERACTIVE
------------------------------------------- */

export const loadChart = ({ state }) => {
  const chart = state.chartGetter()
  if (chart) {
    chart.loadData()
  }
}
export const setDefaultInteractiveChart = ({ commit }, params) => {
  const chart = new ModchartsInteractive(null, params)
  commit(types.SET_CHART_GETTER, () => chart)
}
