<template>
  <div
    ref="tooltip"
    id="date"
    :style="`left: ${positionLeft}px; top: ${positionTop}px;`"
  >
    <div class="date-hover">
      <div>
        <span class="date-value">{{ date }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import moment from 'moment-timezone'
export default {
  name: 'DateSelection',
  computed: {
    ...mapGetters(['chartGetter'])
  },
  mounted() {
    const chart = this.chartGetter()
    chart.eventEmitter.on('CROSSHAIR_MOVE', (args) => {
      if (!args.activePanel.isUpper) {
        this.hideHoverDate()
      } else {
        this.updateHoverDate(args)
      }
    })
    chart.eventEmitter.on('CROSSHAIR_HIDE', () => {
      this.hideHoverDate()
    })
  },
  data() {
    return {
      date: null,
      positionTop: null,
      positionLeft: null
    }
  },
  methods: {
    updateHoverDate(args) {
      this.date = this.formatDate(args.date)
      this.positionTooltip()
    },
    hideHoverDate() {
      const el = this.$refs.tooltip
      if (el) {
        el.style.display = 'none'
      }
    },
    positionTooltip() {
      const el = this.$refs.tooltip
      el.style.display = 'block'
      this.positionTop = 1
      this.positionLeft = 1
    },
    formatDate(date) {
      const curInt = this.$store.getters.interval
      /**
       * Note: the timestamp returned from ModCharts shows up with
       * the browser's timezone but actually represents the tz of the
       * symbol's exchange. The following logic is to correct this discrepancy
       */
      const chart = this.chartGetter()
      //get utcOffset of symbol's exchange
      const exchangeOffset = chart.xref[Object.keys(chart.xref)[0]].utcOffset
      //calculate relative offset between exchange and browser timezome
      const relativeOffset = exchangeOffset - moment().utcOffset()
      //correct for the offset and convert to Eastern time
      const m = moment
        .utc(date)
        .subtract(relativeOffset, 'minutes')
        .tz('America/New_York')

      if (curInt === 5 || curInt === 15) {
        this.period = 'intraday'
        return m.format('MMM DD, YYYY  hh:mm  A z')
      }
      this.period = 'interday'
      return m.format('MMM DD, YYYY')
    }
  }
}
</script>
<style lang="scss">
#date {
  color: white;
  font-size: 10px;
  font-weight: 500;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: none;
  position: absolute;
  background-color: rgba(74, 74, 74, 0.7);
  opacity: 1;
  z-index: 1;
}
#date-value {
  padding-left: 2px;
}
</style>
