<template>
  <table class="ada-hidden-table">
    <caption>Chart Data Table</caption>
    <thead>
      <tr>
        <th>Date</th>
        <th>Open</th>
        <th>High</th>
        <th>Low</th>
        <th>Close</th>
        <th>Volume</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in tableData.series" v-bind:key="key">
        <td aria-label="date">{{ key }}</td>
        <td aria-label="open">{{ item.open }}</td>
        <td aria-label="high">{{ item.high }}</td>
        <td aria-label="low">{{ item.low }}</td>
        <td aria-label="close">{{ item.close }}</td>
        <td aria-label="volume">{{ item.volume }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DataTable',
  data() {
    return {
      tableData: {}
    }
  },
  computed: {
    ...mapGetters(['chartGetter'])
  },
  mounted() {
    const chart = this.chartGetter()
    chart.eventEmitter.on('DATA_LOAD_COMPLETE', () => {
      this.getTableData()
    })
  },

  methods: {
    getTableData() {
      let val = {}
      const chart = this.chartGetter()
      if (chart) {
        val = chart.exportData()
      }
      this.tableData = val
    },
    exportData() {
      const chart = this.chartGetter()
      const exportData = chart.exportData()
      const arrayContent = [['Date', 'Open', 'High', 'Low', 'Close', 'volume']]
      Object.keys(exportData.series).forEach((key) => {
        const row = exportData.series[key]
        arrayContent.push([
          key.replace(', ', ' '),
          row.open,
          row.high,
          row.low,
          row.close,
          row.volume
        ])
      })
    }
  }
}
</script>
<style scoped>
.ada-hidden-table {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
</style>
