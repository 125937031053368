// 'use strict'

//prettier {semi: false} seems to always insert a semicolon before iife's for some reason
// eslint-disable-next-line
;(function() {
  // CustomEvent poyfill for IE :(
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
  if (typeof window.CustomEvent === 'function') return false

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  window.CustomEvent = CustomEvent
})()

const events = {
  tokenExpiredEvent: new CustomEvent('MD_tokenExpired', { bubbles: true })
}

export const tokenExpiredEvent = () =>
  document.dispatchEvent(events.tokenExpiredEvent)
