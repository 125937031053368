/*
 * This is the base class that all HTTP requests should inherit from.
 * HTTP requests should:
 *
 * - Be pure functions
 * - Not affect the store/application state
 * - Return promises
 * If you need to modify the store, use actions. Split data calls into separate files as needed
 * for readability.
 */

import axios from 'axios'
import qs from 'query-string'
import ls from 'local-storage'

import * as config from './config'
import apiUrlHelper from '_helpers/apiUrlHelper'

export default function httpClient(serviceName) {
  const serviceConfig = config[serviceName]
  const baseURL = `${apiUrlHelper()}${serviceConfig.path}`
  const http = axios.create({
    ...serviceConfig.defaultOptions,
    baseURL
  })

  // Append or remove ..showdebuginfo.. to requests based on
  // whether the flag is on the querystring. Caches this in localstorage
  // until the flag is turned off again
  const debugRequestInterceptor = (config) => {
    const qsParams = qs.parse(window.location.search)
    const isShowDebugOn = ls.get('showdebuginfo') === 'on'

    // Turn on showdebuginfo and cache it in localstorage iif
    // the flag is on OR if the flag is cached without the flag turned off
    if (
      (qsParams['..showdebuginfo..'] &&
        qsParams['..showdebuginfo..'] == 'on') ||
      (!qsParams['..showdebuginfo..'] && isShowDebugOn)
    ) {
      if (!config.params) {
        config.params = {}
      }

      // APIs use debugdata, not debuginfo, for this sort of return
      config.params['..showdebugdata..'] = 'on'
      ls.set('showdebuginfo', 'on')
    } else {
      if (qsParams['..showdebuginfo..']) {
        ls.set('showdebuginfo', 'off')
        if (!config.params) {
          config.params = {}
        }
        config.params['..showdebugdata..'] = 'off'
      }
    }
    return config
  }

  const debugResponseInterceptor = (response) => {
    const isShowDebugOn = ls.get('showdebuginfo') === 'on'
    const isApiRequest = response.config.baseURL === baseURL

    // Debuginfo returns application/json that needs to be evaluated
    // in order for the plugin to work
    if (isShowDebugOn && isApiRequest) {
      let o = null
      eval(`o = ${response.data}`)
      return { data: o }
    }

    return response
  }

  // Add showdebuginfo
  http.interceptors.request.use(debugRequestInterceptor)
  http.interceptors.response.use(debugResponseInterceptor)

  return http
}
