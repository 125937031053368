<template>
  <div id="MD_chart">
    <figure>
      <div class="chart-caption">
        Price + Volume
        <div class="chart-timeframe-disclaimer">
          Displaying last 10 years of data for securities older than 10 years
        </div>
      </div>
      <div class="settings-bar">
        <TimeSelection />
        <LineBar />
      </div>
      <div ref="chart" id="modchart" class="modchart-interactive">
        <CrosshairInteractiveLegend />
        <DateSelection />
      </div>
    </figure>
    <div class="delay-disclaimer"><RealTimeDisclaimer :isQuote="false" /></div>
    <DataTable class="p-3" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Crosshair } from '@markit/modcharts/dist/modcharts.esm.js'

import CrosshairInteractiveLegend from '@/components/overlays/CrosshairInteractiveLegend'
import DateSelection from '@/components/overlays/DateSelection'
import TimeSelection from '@/components/settings/TimeSelection'
import LineBar from '@/components/settings/LineBar'
import DataTable from '@/components/DataTable'
import authToken from '@/helpers/authToken'
import apiUrlHelper from '@/helpers/apiUrlHelper'
import RealTimeDisclaimer from '@/components/RealTimeDisclaimer'

export default {
  name: 'ChartInteractive',
  components: {
    TimeSelection,
    LineBar,
    DataTable,
    CrosshairInteractiveLegend,
    DateSelection,
    RealTimeDisclaimer
  },
  computed: {
    loadAfterXids() {
      return this.$store.getters.issueId
    },
    ...mapGetters([
      'days',
      'priceMarkerType',
      'issueId',
      'lowerIndicators',
      'chartGetter'
    ]),
    chartParams() {
      const params = {
        apiPath: `${apiUrlHelper()}apiman-gateway/MOD/chartworks-data/1.0/`,
        symbol: this.issueId,
        showFlags: true,
        days: this.days,
        setDataPeriod: 'days',
        dataInterval: this.interval,
        backfill: true,
        panelHeightUpper: 180,
        panelHeightLower: 65,
        zoomEnabled: false,
        minMarketOpen: false,
        rulerIntradayStop: 1,
        crosshair: { enabled: true },
        panelXAxis: 'last',
        style: {
          indicator: {
            price: {
              color: '#374761',
              width: 1,
              background: {
                color: '#374761',
                colorStop: '#E1FAF4'
              },
              posneg: {
                negative: {
                  background: {
                    color: '#374761',
                    colorStop: '#E1FAF4'
                  }
                },
                positive: {
                  background: {
                    color: 'rgb(0,128,0)',
                    colorStop: 'rgb(178, 34, 34)'
                  }
                }
              },
              grid: {
                color: '#FF0000'
              }
            },
            volume: {
              color: '#374761',
              width: 2,
              lineDash: '5,5,1,2'
            }
          },
          tool: {
            text: {
              color: '#192F41',
              width: 2
            },
            ellipse: {
              color: '#2496F1',
              width: 2,
              boundingBox: {
                color: '#FF0000'
              }
            }
          },
          panel: {
            padding: { bottom: 30 },
            grid: {
              color: 'transparent',
              vertical: {
                alt: {
                  color: 'transparent'
                }
              },
              horizontal: { color: '#D7D7D7' }
            },
            border: {
              top: { color: '#D7D7D7' },
              bottom: { color: '#D7D7D7' },
              left: { color: '#F7F7F7' },
              right: { color: '#F7F7F7' }
            },
            yAxis: {
              label: {
                format: {
                  default: { format: '$0,.2f' }
                },
                padding: {
                  top: 40,
                  bottom: 10
                }
              }
            }
          }
        }
      }
      const panels = [
        {
          indicators: [
            {
              id: 'price',
              markerType: this.priceMarkerType
            }
          ],
          params: { yAxisFormat: 'currency' }
        },
        {
          indicators: [{ id: 'volume' }]
        }
      ]
      return {
        params: params,
        panels: panels
      }
    }
  },
  created() {
    this.setDefaultInteractiveChart(this.chartParams.params)
    this.chart = window.chart = this.chartGetter()
    this.chart.setAuthToken(authToken())
  },
  data() {
    return {
      currentTool: null,
      shouldShowToolConfig: false
    }
  },
  methods: {
    ...mapMutations(['SET_CHART_GETTER']),
    ...mapActions(['loadChart', 'setDefaultInteractiveChart']),
    addChartEventListeners() {
      this.chart.eventEmitter.on('TOOL_DOUBLE_CLICK', (tool) => {
        this.currentTool = tool
        this.shouldShowToolConfig = true
      })
      this.chart.eventEmitter.on('CLICK', () => {
        this.shouldShowToolConfig = false
      })
    }
  },
  watch: {
    chartGetter() {
      this.$nextTick(() => {
        this.chart.mount(this.$refs.chart)
        this.chart.load(this.chartParams)
        this.crosshair = new Crosshair(this.chart, {
          flagEnabled: true,
          snapHorizontalToData: true,
          primaryOnly: true
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/style/modcharts';
$panel-margin: 15px;
$primary-panel-height: 180px;
$secondary-panel-height: 65px;

#MD_chart {
  border: none;
  .modchart-interactive {
    height: $primary-panel-height + $secondary-panel-height +
      ($panel-margin * 2);

    .modcharts-panel {
      margin-top: 25px;
      margin-bottom: 15px;
      position: relative !important;
    }

    .modcharts-flag-lastclose polygon {
      fill: #364662 !important;
    }
  }
  figure {
    margin-top: 0px;
    margin-bottom: 2%;
  }
  .chart-caption {
    display: inline-block;
    color: #243248;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 1%;
  }
  .chart-timeframe-disclaimer {
    padding-left: 5px;
    display: inline-block;
    height: 16px;
    width: 300px;
    color: #595959;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
  }
  .settings-bar {
    border-bottom: 1px solid #d7d7d7;
    height: 25px;
    margin-right: 50px;
    margin-bottom: 22px;
  }
  div.delay-disclaimer {
    width: 100%;
    float: right;
    margin-top: 2%;
    margin-right: 11%;
  }
}
</style>
