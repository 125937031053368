<template>
  <span
    :class="isQuote ? 'realtime-disclaimer-quote' : 'realtime-disclaimer-chart'"
    >15-minute delayed quote: {{ getToday(quoteDetails) }}</span
  >
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  name: 'RealTimeDisclaimer',
  props: ['isQuote'],
  methods: {
    getToday(ds) {
      if (!ds.lastTrade || !ds.lastTrade.date) {
        return '--'
      }
      let date = moment.utc(ds.lastTrade.date).tz('America/New_York')
      return date.format('MMM D, YYYY, h:mm A z')
    }
  },
  computed: {
    ...mapState(['quoteDetails'])
  }
}
</script>

<style scoped>
.realtime-disclaimer-quote {
  padding-left: 5px;
  display: inline-block;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
  color: #595959;
  font-weight: 400;
}
.realtime-disclaimer-chart {
  padding-left: 5px;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
  color: #595959;
  font-weight: 400;
  float: right;
}
</style>
