<template>
  <div id="MD_quote-bar">
    <div class="quote-caption">
      Quote Details
      <RealTimeDisclaimer :isQuote="true" />
    </div>
    <PriceDetailsTable :quoteData="quoteDetails" />

    <div class="horiz-rule"></div>

    <RangeTable :quoteData="quoteDetails" />

    <div class="horiz-rule"></div>

    <VolumeTable :quoteData="quoteDetails" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PriceDetailsTable from './PriceDetailsTable'
import RangeTable from './RangeTable'
import VolumeTable from './VolumeTable'
import RealTimeDisclaimer from '_components/RealTimeDisclaimer'

export default {
  name: 'QuoteBar',
  components: {
    PriceDetailsTable,
    RangeTable,
    VolumeTable,
    RealTimeDisclaimer
  },
  computed: {
    ...mapState(['quoteDetails'])
  }
}
</script>

<style lang="less" scoped>
#MD_quote-bar {
  color: #4a4a4a;
  font-family: Fira Sans, sans-serif;
  font-size: smaller;

  .quote-caption {
    display: inline-block;
    color: #243248;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 6.2%;
  }

  .horiz-rule {
    height: 1px;
    border-top: #d7d7d7 2px solid;
    margin-top: 4%;
  }
}
</style>
