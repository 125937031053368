<template>
  <div id="MD_price-details-table">
    <table>
      <thead>
        <tr>
          <th class="headerformat">Last Price</th>
          <th class="headerformat">Today’s Change</th>
          <th class="headerformat">Previous Close</th>
          <th class="headerformat">Open</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td aria-label="Last Price">
            {{
              quoteData.lastTrade && quoteData.lastTrade.last
                ? `$${quoteData.lastTrade.last.toFixed(2)}`
                : '--'
            }}
          </td>
          <td :class="dynamicChangeClass" aria-label="Today's Change">
            {{ getDynamicChangePrice() }}&nbsp;/&nbsp;{{
              getDynamicChangePercent()
            }}
          </td>
          <td aria-label="Previous Close">
            {{
              quoteData.lastTrade && quoteData.lastTrade.close
                ? `$${quoteData.lastTrade.close.toFixed(2)}`
                : '--'
            }}
          </td>
          <td aria-label="Open">
            {{
              quoteData.lastTrade && quoteData.lastTrade.open
                ? `$${quoteData.lastTrade.open.toFixed(2)}`
                : '--'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PriceDetailsTable',
  props: ['quoteData'],
  data() {
    return {
      dynamicChangeClass: ''
    }
  },
  methods: {
    isPositive(num) {
      return num >= 0
    },
    getDynamicChangePrice() {
      if (!this.quoteData.lastTrade || !this.quoteData.lastTrade.change)
        return '--'

      const changePrice = this.quoteData.lastTrade.change.toFixed(2)
      const isPositive = this.isPositive(changePrice)
      const operator = isPositive ? '+' : '-'

      // Set the green/red class based on price because a negative price
      // also means a negative percentage.
      this.dynamicChangeClass = isPositive
        ? 'positive-change-value'
        : 'negative-change-value'

      return `${operator}$${changePrice.toString().replace('-', '')}`
    },
    getDynamicChangePercent() {
      if (!this.quoteData.changePercent || !this.quoteData.changePercent.today)
        return '--'

      const changePercent = this.quoteData.changePercent.today.toFixed(2)
      const operator = this.isPositive(changePercent) ? '+' : ''

      return `${operator}${changePercent}%`
    }
  }
}
</script>

<style lang="less" scoped>
#MD_price-details-table {
  color: #4a4a4a;

  table {
    width: 100%;

    thead {
      th {
        border: none !important;
        font-weight: normal;
        text-align: left;
        padding-left: 0;
      }
    }

    tbody {
      font-weight: bold;

      td {
        border: none !important;
        padding-left: 0;
      }

      .positive-change-value {
        color: #3f8000;
      }

      .negative-change-value {
        color: #9b0e0e;
      }
    }
    .headerformat {
      padding-bottom: 1.8%;
    }
  }
}
</style>
