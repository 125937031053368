'use strict'

import { createStore } from 'vuex'

import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  xref: {
    currentXids: {},
    companyName: ''
  },
  chart: {
    id: 3, // 3: equity, 18,: etf, 2: Derivative, 1: debt
    comparisons: [],
    chartGetter: () => null,
    days: 365,
    interval: 1,
    period: 'Day',
    locale: 'en_US',
    priceMarkerType: 'fill',
    issueId: 36276,
    symbol: '',
    upperIndicators: [],
    lowerIndicators: [],
    tool: null,
    events: []
  },
  inceptionDate: '',
  xrefSuccess: false,
  quoteSuccess: false,
  quoteDetails: {},
  iterator: 0
}

export default createStore({
  state,
  getters,
  mutations,
  actions
})
