'use strict'
import App from './App.vue'
import store from './store'
import { createApp } from 'vue'

const createMyApp = () => {
  const appInstance = createApp(App)
  appInstance.use(store)
  return appInstance
}

let appInstance = createMyApp()

// Expose a public function for Atria to call that
// creates a new instance with a symbol passed.
window.MD_renderQuoteDrawer = (symbol) => {
  appInstance.unmount()
  appInstance = createMyApp()
  appInstance.provide('symbolInput', symbol)
  appInstance.mount('#MD_app')
}

// Attempt to load as normal for internal testing *without* a symbol
//ppInstance.$mount('#MD_VueApp')
//appInstance.$emit('symbol-input', '')
