'use strict'

export const currentXids = (state) => state.xref.currentXids
export const companyName = (state) => state.xref.companyName
export const comparisons = (state) => state.chart.comparisons
export const chartGetter = (state) => state.chart.chartGetter
export const days = (state) => state.chart.days
export const interval = (state) => state.chart.interval
export const issueId = (state) => state.chart.issueId
export const symbol = (state) => state.chart.symbol
export const period = (state) => state.chart.period
export const locale = (state) => state.chart.locale
export const priceMarkerType = (state) => state.chart.priceMarkerType
export const upperIndicators = (state) => state.chart.upperIndicators
export const lowerIndicators = (state) => state.chart.lowerIndicators
export const tool = (state) => state.chart.tool
export const events = (state) => state.chart.events
export const inceptionDate = (state) => state.inceptionDate
export const xrefSuccess = (state) => state.xrefSuccess
export const quoteSuccess = (state) => state.quoteSuccess
