'use strict'

import moment from 'moment'
import msMoment from 'moment-msdate'

export default (inceptionDate) => {
  const defaultOptions = {
    '1d': {
      days: 1,
      label: '1d',
      selected: 0,
      period: 'Minute',
      intraday: 5
    },
    '5d': {
      days: 5,
      label: '5d',
      selected: 1,
      period: 'Minute',
      intraday: 15
    },
    '1m': {
      days: 30,
      label: '1m',
      selected: 2,
      period: 'Day',
      intraday: 1
    },
    '6m': {
      days: 180,
      label: '6m',
      selected: 3,
      period: 'Day',
      intraday: 1
    },
    '1yr': {
      days: 365,
      label: '1yr',
      selected: 4,
      period: 'Day',
      intraday: 1
    },
    '5yr': {
      days: 365 * 5,
      label: '5yr',
      selected: 5,
      period: 'Month',
      intraday: 1
    },
    '10yr': {
      days: 365 * 10,
      label: '10yr',
      selected: 6,
      period: 'Month',
      intraday: 1
    }
  }

  if (inceptionDate) {
    const incep = msMoment.fromOADate(inceptionDate)
    const today = moment()

    const diff = today.diff(incep, 'days')
    //console.log('today ' +  today.format('L ')  + ' inception ' + incep.format('L ') + ' difference ' + diff)
    if (diff < 1) {
      return {
        max: {
          days: diff,
          label: 'max',
          selected: 0,
          period: 'Minute',
          intraday: 5
        }
      }
    } else if (diff < 5) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        max: {
          days: diff,
          label: 'max',
          selected: 1,
          period: 'Minute',
          intraday: 15
        }
      }
    } else if (diff < 30) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        max: {
          days: diff,
          label: 'max',
          selected: 2,
          period: diff < 10 ? 'Minute' : 'Day',
          intraday: 60
        }
      }
    } else if (diff < 90) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        '1m': {
          days: 30,
          label: '1m',
          selected: 2,
          period: 'Day',
          intraday: 1
        },
        max: {
          days: diff,
          label: 'max',
          selected: 3,
          period: 'Day',
          intraday: 1
        }
      }
    } else if (diff < 180) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        '1m': {
          days: 30,
          label: '1m',
          selected: 2,
          period: 'Day',
          intraday: 1
        },
        max: {
          days: diff,
          label: 'max',
          selected: 3,
          period: 'Day',
          intraday: 1
        }
      }
    } else if (diff < 365) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        '1m': {
          days: 30,
          label: '1m',
          selected: 2,
          period: 'Day',
          intraday: 1
        },
        '6m': {
          days: 180,
          label: '6m',
          selected: 3,
          period: 'Day',
          intraday: 1
        },
        max: {
          days: diff,
          label: 'max',
          selected: 4,
          period: 'Day',
          intraday: 1
        }
      }
    } else if (diff < 1825) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        '1m': {
          days: 30,
          label: '1m',
          selected: 2,
          period: 'Day',
          intraday: 1
        },
        '6m': {
          days: 180,
          label: '6m',
          selected: 3,
          period: 'Day',
          intraday: 1
        },
        '1yr': {
          days: 365,
          label: '1yr',
          selected: 4,
          period: 'Day',
          intraday: 1
        },
        max: {
          days: diff,
          label: 'max',
          selected: 5,
          period: 'Month',
          intraday: 1
        }
      }
    } else if (diff < 3650) {
      return {
        '1d': {
          days: 1,
          label: '1d',
          selected: 0,
          period: 'Minute',
          intraday: 5
        },
        '5d': {
          days: 5,
          label: '5d',
          selected: 1,
          period: 'Minute',
          intraday: 15
        },
        '1m': {
          days: 30,
          label: '1m',
          selected: 2,
          period: 'Day',
          intraday: 1
        },
        '6m': {
          days: 180,
          label: '6m',
          selected: 3,
          period: 'Day',
          intraday: 1
        },
        '1yr': {
          days: 365,
          label: '1yr',
          selected: 4,
          period: 'Day',
          intraday: 1
        },
        '5yr': {
          days: 365 * 5,
          label: '5yr',
          selected: 5,
          period: 'Month',
          intraday: 1
        },
        max: {
          days: diff,
          label: 'max',
          selected: 6,
          period: 'Month',
          intraday: 1
        }
      }
    } else {
      // there is greater than 10 years
      return defaultOptions
    }
  } else {
    // no inception date
    return defaultOptions
  }
}
