<template>
  <div id="MD_volume-table">
    <table>
      <thead>
        <tr>
          <th class="headerformat">Bid / Ask</th>
          <th class="headerformat">Today's Volume</th>
          <th class="headerformat">Avg 90-Day Volume</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td aria-label="Bid / Ask">
            {{
              quoteData.bid && quoteData.bid.price
                ? `$${quoteData.bid.price.toFixed(2)}`
                : '--'
            }}
            /
            {{
              quoteData.ask && quoteData.ask.price
                ? `$${quoteData.ask.price.toFixed(2)}`
                : '--'
            }}
          </td>
          <td aria-label="Today's Volume">
            {{
              quoteData.volume && quoteData.volume.last
                ? formatVolume(quoteData.volume.last)
                : '--'
            }}
          </td>
          <td aria-label="Average 90-Day Volume">
            {{
              quoteData.volume && quoteData.volume.average90Day
                ? formatVolume(quoteData.volume.average90Day)
                : '--'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VolumeTable',
  props: ['quoteData'],
  methods: {
    formatVolume(num) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num)
    }
  }
}
</script>

<style lang="less" scoped>
#MD_volume-table {
  color: #4a4a4a;
  margin-top: 3.8%;

  table {
    width: 100%;

    thead {
      th {
        border: none !important;
        font-weight: normal;
        text-align: left;
        padding-left: 0;
      }
    }

    tbody {
      font-weight: bold;

      td {
        border: none !important;
        padding-left: 0;
      }
    }

    .headerformat {
      padding-bottom: 1.8%;
    }
  }
}
</style>
