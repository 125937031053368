<template>
  <div class="circle">
    <svg
      class="circle__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle class="circle__svg-circle" cx="50" cy="50" r="25" />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'LoadingView'
}
</script>
<style lang="scss" scoped>
$circle-size: 100px;
$circle-stroke-color: gray;
$circle-radius: 25;

@function get-dash-value($radius, $percentage) {
  // Using $radius, calculate circumference.
  $circumference: 2 * 3.1415927 * $radius;

  // Convert percentage to decimal.
  // i.e. 50% = 0.5.
  $percentage-as-decimal: $percentage / 100%;

  // Return unit value.
  @return $circumference * $percentage-as-decimal;
}

.circle {
  margin: 0 50%;
  width: $circle-size;
}

.circle__svg {
  animation: 2s linear infinite both circle__svg;
}

@keyframes circle__svg {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.circle__svg-circle {
  animation: 1.4s ease-in-out infinite both circle__svg-circle;
  fill: transparent;
  stroke: $circle-stroke-color;
  stroke-dasharray: get-dash-value($circle-radius, 100%);
  stroke-linecap: round;
  stroke-width: 5px;
  transform-origin: 50% 50%;
}

@keyframes circle__svg-circle {
  0%,
  25% {
    stroke-dashoffset: get-dash-value($circle-radius, 97%);
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: get-dash-value($circle-radius, 25%);
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: get-dash-value($circle-radius, 97%);
    transform: rotate(360deg);
  }
}
</style>
