<template>
  <div id="MD_line-bar">
    <span>
      <span
        class="line-label"
        :class="{ 'active-button': isActive(chartTypes.fill.label) }"
      >
        <button
          aria-label="line chart selector"
          class="line-button"
          :class="{ 'line-button-active': isActive(chartTypes.fill.label) }"
          @click="setChartType(chartTypes.fill.label)"
        />
      </span>

      <span class="dividing-rule-1">|</span>

      <span
        class="ohlc-label"
        :class="{ 'active-button': isActive(chartTypes.ohlc.label) }"
      >
        <button
          aria-label="ohlc chart selector"
          class="ohlc-button"
          :class="{ 'ohlc-button-active': isActive(chartTypes.ohlc.label) }"
          @click="setChartType(chartTypes.ohlc.label)"
        />
      </span>
    </span>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      chartTypes: {
        fill: {
          label: 'fill',
          indexed: 1,
          isFill: true
        },
        ohlc: {
          label: 'ohlc',
          indexed: 0,
          isOhlc: false
        }
      },

      isLineChart: 'fill'
    }
  },
  methods: {
    ...mapMutations(['SET_PRICE_MARKER_TYPE']),
    setChartType(value) {
      this.SET_PRICE_MARKER_TYPE(value)
      this.isLineChart = value
    },
    isActive(val) {
      return this.isLineChart === val
    }
  }
}
</script>

<style lang="scss" scoped>
#MD_line-bar {
  display: inline;
  float: right;

  .dividing-rule-1 {
    color: #333;
    height: 22px;
    width: 2px;
    opacity: 0.3;
  }

  .line-label {
    cursor: pointer;
  }
  .bar-label {
    cursor: pointer;
  }
  .line-button {
    cursor: pointer;
    border: 0px solid transparent;
    outline: none;

    margin-left: 14px;
    margin-right: 14px;
    background: url('../../assets/sprite.png') no-repeat;
    background-position: -27px -114px;
    width: 15px;
    height: 9px;

    &-active {
      background-position: -5px -114px;
      width: 17px;
      height: 9px;
    }
  }

  .active-button {
    padding-bottom: 1px;
    border: 0px solid transparent;
    outline: none;
    background-position: -5px -110px;
    border-bottom: 2px solid #374761;
  }
  @-moz-document url-prefix() {
    .line-button,
    .ohlc-button {
      padding-top: 8px;
    }
    .active-button {
      padding-bottom: 4px !important;
    }
  }
  .ohlc-button {
    cursor: pointer;
    border: 0px solid transparent;
    outline: none;
    margin-left: 14px;
    margin-right: 14px;
    background: url('../../assets/sprite.png') no-repeat;
    background-position: -67px -114px;
    width: 15px;
    height: 19px;

    &-active {
      background-position: -47px -114px;
      width: 15px;
      height: 19px;
    }
  }
}
</style>
