'use strict'

import authToken from '_helpers/authToken'
import httpClient from './httpClient'

export default (serviceName) => {
  if (!serviceName) throw new ReferenceError('serviceName required')

  const service = httpClient(serviceName)

  const get = (url, params, opts) => {
    return service({
      ...opts,
      ...{
        url,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken()}`
        }
      }
    })
  }

  const post = (url, data, opts) => {
    return service({
      ...opts,
      ...{
        url,
        data,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken()}`
        }
      }
    })
  }

  // 'delete' is a reserved keyword in native JavaScript
  const _delete = (url, data, opts) => {
    return service({
      ...opts,
      ...{
        url,
        data,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken()}`
        }
      }
    })
  }

  const put = (url, data, opts) => {
    return service({
      ...opts,
      ...{
        url,
        data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken()}`
        }
      }
    })
  }

  // Export the base service & the other HTTP methods
  return {
    service,
    get,
    post,
    _delete,
    put
  }
}
