<template>
  <div id="MD_time">
    <ul aria-label="selectable time periods" v-if="this.issueId">
      <li
        class="time-label"
        v-for="(option, idx) in Object.keys(options)"
        @click="setTimeSelected(options[option].label)"
        :key="option"
      >
        <span
          class="digit-label"
          :class="{ 'time-active': idx == activeIndex }"
          :aria-label="adaLabels[option]"
          >{{ options[option].label }}</span
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import maxTime from '_helpers/maxTime'

export default {
  name: 'TimeSelection',
  data() {
    return {
      activeColor: String,
      activeIndex: 0,
      selectedTime: '1d',
      periodsIntraday: ['Minute', 'Hour'],
      adaLabels: {
        '1d': '1 day',
        '5d': '5 day',
        '1m': '1 month',
        '6m': '6 months',
        '1yr': '1 year',
        '5yr': '5 years',
        '10yr': '10 years'
      },
      options: {},
      optionsReady: false,
      periodsInterday: ['Day', 'Week', 'Month']
    }
  },
  methods: {
    ...mapMutations(['SET_DAYS', 'SET_PERIOD', 'SET_INTERVAL']),
    ...mapActions(['getinceptionDate']),
    async getOptions() {
      await this.getinceptionDate(this.issueId)
      this.options = maxTime(this.inceptionDate)
      if ('1yr' in this.options) {
        this.activeIndex = this.options['1yr'].selected
        this.selectedTime = this.options['1yr'].label
        this.SET_DAYS(this.options['1yr'].days)
        this.SET_PERIOD(this.options['1yr'].period)
        this.SET_INTERVAL(this.options['1yr'].intraday)
      } else {
        this.activeIndex = this.options['max'].selected
        this.selectedTime = this.options['max'].label
        this.SET_DAYS(this.options['max'].days)
        this.SET_PERIOD(this.options['max'].period)
        this.SET_INTERVAL(this.options['max'].intraday)
      }
    },
    updateTime() {
      const days = this.options[this.selectedTime].days
      const period = this.options[this.selectedTime].period
      const intraday = this.options[this.selectedTime].intraday
      this.SET_DAYS(days)
      this.SET_PERIOD(period)
      this.SET_INTERVAL(intraday)
    },
    setTimeSelected(value) {
      this.selectedTime = value
      this.activeIndex = this.options[value].selected
      this.updateTime()
    }
  },
  created() {
    this.getOptions()
  },
  computed: {
    ...mapGetters(['days', 'period', 'issueId', 'inceptionDate']),
    loadAfterXids() {
      return this.$store.getters.issueId
    },
    ...mapState(['iterator'])
  },
  watch: {
    iterator() {
      this.getOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
#MD_time {
  display: inline;
  @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap');
  .time-frame {
    text-decoration: none;
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: inline;
    li {
      border: none;
      float: none;
      display: inline;
      background: transparent;
      padding: 0;

      .time-label {
        height: 21px;
      }
      span {
        &.dividing-rule-1 {
          color: #333;
          height: 22px;
          width: 2px;
          opacity: 0.3;
        }
        &.digit-label {
          height: 18px;
          color: #4a4a4a;
          font-family: 'Assistant', sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 18px;
          min-width: 35px;
          text-align: center;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
        }
        &.time-active {
          color: #4a4a4a;
          font-family: 'Fira Sans', sans-serif;
          font-weight: bold;
          border-bottom: 2px solid #374761;
          max-width: 40px;
          height: 18px;
          text-align: center;
          padding-left: 14px;
          padding-right: 14px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
      .line-bar {
        float: right;
        margin-right: 25px;
      }
    }
  }
}
</style>
