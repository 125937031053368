'use strict'

export const XREF_CURRENT_XIDS = 'XREF_CURRENT_XIDS'
export const XREF_COMPANY_NAME = 'XREF_COMPANY_NAME'
export const CHART_VENUE = 'CHART_VENUE'
export const QUOTE_DETAILS = 'QUOTE_DETAILS'
export const CHART_SYMBOL = 'CHART_SYMBOL'
export const SET_CHART_GETTER = 'SET_CHART_GETTER'
export const XREF_SUCCESS = 'XREF_SUCCESS'
export const QUOTE_SUCCESS = 'QUOTE_SUCCESS'
export const SET_DAYS = 'SET_DAYS'
export const SET_PERIOD = 'SET_PERIOD'
export const SET_INTERVAL = 'SET_INTERVAL'
export const SET_PRICE_MARKER_TYPE = 'SET_PRICE_MARKER_TYPE'
export const INCEPTION_DATE = 'INCEPTION_DATE'
export const ITERATOR = 'ITERATOR'
