<template>
  <div id="MD_app">
    <div v-if="isLoading">
      <!-- this should be designed -->
      <Loading />
    </div>
    <div v-else>
      <table v-if="quoteSuccess && xrefSuccess" role="presentation">
        <tr>
          <td class="quotes">
            <QuoteBar />
          </td>
          <td id="MD_charts">
            <ModchartsInteractive />
          </td>
        </tr>
      </table>

      <div v-else class="error">
        <div class="missing"></div>
        <div class="error-block">
          <p>Quote details are unavailable.</p>
          <p v-if="!xrefSuccess">
            The security selected is not a Stock or ETF.
          </p>
          <p v-else-if="!quoteSuccess">
            Please try refreshing the page or check back in a few minutes.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import QuoteBar from './components/QuoteBar/Main.vue'
import ModchartsInteractive from './components/ChartInteractive.vue'
import Loading from './components/LoadingView.vue'

export default {
  name: 'App',
  data() {
    return {
      isLoading: true
    }
  },
  inject: ['symbolInput'],
  components: {
    QuoteBar,
    ModchartsInteractive,
    Loading
  },
  methods: {
    ...mapActions(['xrefPredictiveSearch', 'getQuoteData']),
    async getData(symbol) {
      await this.xrefPredictiveSearch(symbol)
      await this.getQuoteData()
    },
    async symbolUpdate() {
      this.isLoading = true
      if (!this.symbolInput || typeof this.symbolInput !== 'string') return
      if (this.symbolInput.indexOf('+') != -1) {
        this.symbolInput = this.symbolInput.replace('+', '.')
      }
      await this.getData(this.symbolInput)
      this.isLoading = false
    }
  },
  computed: {
    ...mapState(['xrefSuccess', 'quoteSuccess'])
  },
  async created() {
    await this.symbolUpdate()
  }
}
</script>

<style lang="less" scoped>
@import (css)
  url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap');

#MD_app {
  margin: 0;
  background-color: #f7f7f7;
  font-family: 'Fira Sans', sans-serif;
  white-space: nowrap;

  p.loading {
    text-align: center;
  }

  table {
    width: 104%;
  }
  td {
    border: 0px;
  }
  .quotes {
    width: 35%;
    vertical-align: top;
    border-bottom: 0px transparent #f7f7f7 !important;
    padding: 0;
  }
  #MD_charts {
    width: 65%;
    vertical-align: top;
    // force deploy
    border: none;
    border-bottom: 0px transparent #f7f7f7 !important;
    padding: 0;
    &.td {
      border-bottom: 0px transparent #f7f7f7 !important;
    }
  }
  .error {
    width: 100%;
    padding-top: 8%;
    padding-bottom: 10%;
    text-align: center;
    background-position: center;
  }
  .missing {
    margin: 0 auto;
    background: url('assets/sprite.png') -5px -0px;
    width: 111px;
    height: 109px;
    margin-bottom: 2%;
  }
  .error-block {
    width: 100%;
    height: 52px;
    p {
      color: #233249;
      font-family: 'Fira Sans', sans-serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 26px;
      max-width: 100%;
    }
  }
}
</style>
