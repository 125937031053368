'use strict'

// Each API has it's own set of default options that we set here

export const xref = {
  path: 'xref/v1',
  defaultOptions: {
    headers: {
      Accept: 'application/json'
    }
  }
}

export const quote = {
  path: 'quote/v1',
  defaultOptions: {
    headers: {
      Accept: 'application/json'
    }
  }
}

export const chart = {
  path: 'apiman-gateway/MOD/chartworks-data/1.0/chartapi',
  defaultOptions: {
    headers: {
      Accept: 'application/json'
    }
  }
}
