'use strict'

import * as types from './mutation-types'

export default {
  [types.XREF_CURRENT_XIDS](state, payload) {
    state.xref.currentXids = payload
  },
  [types.XREF_COMPANY_NAME](state, payload) {
    state.xref.companyName = payload
  },
  [types.CHART_VENUE](state, payload) {
    state.chart.issueId = payload
  },
  [types.CHART_SYMBOL](state, payload) {
    state.chart.symbol = payload
  },
  [types.XREF_SUCCESS](state, payload) {
    state.xrefSuccess = payload
  },
  [types.QUOTE_SUCCESS](state, payload) {
    state.quoteSuccess = payload
  },
  [types.INCEPTION_DATE](state, payload) {
    state.inceptionDate = payload
  },
  [types.QUOTE_DETAILS](state, payload) {
    state.quoteDetails = payload
  },
  [types.SET_CHART_GETTER](state, getter) {
    state.chart.chartGetter = getter
  },
  [types.SET_DAYS](state, days) {
    state.chart.days = days
    const chart = state.chart.chartGetter()
    if (chart) {
      chart.setDays(days)
      chart.loadData()
    }
  },
  [types.SET_PERIOD](state, period) {
    state.chart.period = period
    const chart = state.chart.chartGetter()
    if (chart) {
      chart.setDataPeriod(period)
      chart.loadData()
    }
  },
  [types.SET_INTERVAL](state, interval) {
    state.chart.interval = interval
    const chart = state.chart.chartGetter()
    if (chart) {
      chart.setDataInterval(interval)
      chart.loadData()
    }
  },
  [types.SET_PRICE_MARKER_TYPE](state, type) {
    state.priceMarkerType = type
    const chart = state.chart.chartGetter()
    if (chart) {
      // I hope we can do this more gracefully
      const firstPanel = chart.panels[0]
      if (firstPanel) {
        const firstIndicator = firstPanel.indicators[0]
        if (firstIndicator) {
          firstIndicator.params.markerType = type
        }
      }
      chart.render()
    }
  },
  [types.ITERATOR](state, payload) {
    state.iterator = payload
  }
}
