<template>
  <div id="MD_range-table">
    <table>
      <tbody>
        <tr>
          <td>
            <p>Today's Range</p>
            <div
              id="MD_todays-range"
              class="range-slider"
              :aria-label="lastPrice"
            >
              <div class="slider-bar"></div>
              <div
                v-if="todayPercentage !== false"
                class="slider-pointer"
                :style="`left:${todayPercentage}%;`"
              ></div>
            </div>
            <div class="range-points">
              <span aria-label="Today's Low">{{ todayLow.value }}</span>
              <span aria-label="Today's High">{{ todayHigh.value }}</span>
            </div>
          </td>

          <td>
            <p>52-Week Range</p>
            <div
              id="MD_fiveTwo-range"
              class="range-slider"
              :aria-label="lastPrice"
            >
              <div class="slider-bar"></div>
              <div
                v-if="fiveTwoPercentage !== false"
                class="slider-pointer"
                :style="`left:${fiveTwoPercentage}%;`"
              ></div>
            </div>
            <div class="range-points">
              <span aria-label="52-Week Low">{{ fiveTwoLow.value }}</span>
              <span aria-label="52-Week High">{{ fiveTwoHigh.value }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'RangeTable',
  props: ['quoteData'],
  data() {
    return {
      todayLow: { value: '--', raw: false },
      todayHigh: { value: '--', raw: false },
      fiveTwoLow: { value: '--', raw: false },
      fiveTwoHigh: { value: '--', raw: false },
      todayRange: 0,
      fiveTwoRange: 0,
      todayPercentage: false,
      fiveTwoPercentage: false
    }
  },
  methods: {
    checkPercentage(range) {
      if (range < 0) return 0
      if (range > 94) return 94
      return range
    },
    checkData(a, b) {
      if (!this.quoteData[a] || !this.quoteData[a][b])
        return { value: '--', raw: false }
      const raw = this.quoteData[a][b].toFixed(2)
      return {
        value: `$${raw}`,
        raw: parseFloat(raw)
      }
    },
    checkLastPrice() {
      return this.quoteData.lastTrade && this.quoteData.lastTrade.last
    },
    getRangePercentage(low, high) {
      if (!low || !low.raw || !high || !high.raw) return false
      if (!this.quoteData.lastTrade || !this.quoteData.lastTrade.last)
        return false
      return (
        100 * ((this.quoteData.lastTrade.last - low.raw) / (high.raw - low.raw))
      )
    },
    processData() {
      this.todayLow = this.checkData('lastTrade', 'low')
      this.todayHigh = this.checkData('lastTrade', 'high')
      this.fiveTwoLow = this.checkData('price52Week', 'low')
      this.fiveTwoHigh = this.checkData('price52Week', 'high')
      this.todayRange = this.getRangePercentage(this.todayLow, this.todayHigh)
      this.todayPercentage = this.checkPercentage(this.todayRange)
      this.fiveTwoRange = this.getRangePercentage(
        this.fiveTwoLow,
        this.fiveTwoHigh
      )
      this.fiveTwoPercentage = this.checkPercentage(this.fiveTwoRange)

      if (this.checkLastPrice()) {
        this.lastPrice = `$${this.quoteData.lastTrade.last.toFixed(2)}`
      }
    }
  },
  created() {
    this.processData()
  },
  watch: {
    quoteData() {
      this.processData()
    }
  }
}
</script>

<style lang="less" scoped>
#MD_range-table {
  color: #4a4a4a;
  margin-top: 2.5%;

  table {
    width: 100%;

    td {
      width: 45%;
      padding-right: 3%;
      padding-left: 0;
      border: none !important;

      .range-points {
        width: 100%;
        font-weight: bold;
        span {
          width: 50%;
          display: inline-block;
        }

        span:last-child {
          text-align: right;
        }
      }
    }

    td:last-child {
      padding-left: 3%;
      padding-right: 0;
    }

    .range-slider {
      position: relative;
      height: 18px;
      width: 100%;
      margin-top: -3%;
      margin-bottom: 3.6%;

      .slider-bar {
        width: 100%;
        height: 1px;
        border-top: rgb(129, 129, 129) 2px solid;
        position: absolute;
        left: 0;
        top: 8px;
      }

      .slider-pointer {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #4a4a4a;
        border-radius: 12px;
      }
    }
  }
}
</style>
